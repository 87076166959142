<template>
  <LoginLayout v-if="!isLoggedIn">
    <div class="content">
      <div class="error">
        <div class="error-panel">
          <p class="error-title">お探しのページはみつかりませんでした</p>
          <p class="error-text">
            お探しのページは<br />
            ・すでに削除されている<br />
            ・公開期間が終わっている<br />
            ・アクセスしたアドレスが異なっている<br />
            などの理由で見つかりませんでした。
          </p>
          <!-- <router-link class="btn btn-main" to="/">トップページに戻る</router-link> -->
        </div>
      </div>
    </div>
  </LoginLayout>
  <div v-else class="content">
    <div class="error">
      <div class="error-panel">
        <p class="error-title">お探しのページはみつかりませんでした</p>
        <p class="error-text">
          お探しのページは<br />
          ・すでに削除されている<br />
          ・公開期間が終わっている<br />
          ・アクセスしたアドレスが異なっている<br />
          ・該当するエクスポートファイルが存在しません。<br />
          などの理由で見つかりませんでした。
        </p>
        <!-- <router-link class="btn btn-main" to="/">トップページに戻る</router-link> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LoginLayout from '@/components/LoginLayout.vue';

export default {
  data: function() {
    return {
      pageName: '404',
    };
  },
  computed: {
     ...mapGetters({
      isLoggedIn: 'auth/token',
    }),
  },
  components: {
    LoginLayout,
  },
};
</script>
